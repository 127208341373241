.navbar {
    height: 80px;
    background-color: #242424 !important;
}

body {
    background: radial-gradient(gray, black);
    height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
    padding: 5px;
    font-family: Arial;
    text-align: center;
}

.belowNav{
    padding: 12px;
}

.footContainer {
    position: sticky;
    margin-bottom: none;
}

.footer {
    background-color: #242424;
    width: 100%;
}

.footerLink {
    font-size: 17px;
    color: white;
    text-decoration: none;
}

.PhoneImg {
    width: 50vw;
    max-width: 270px;
}

.moneyImg {
    max-width: 200px;
    padding-bottom: 10px;
}

.discordImg {
    max-width: 200px;
}

.navbarImg {
    height: 30px;
    max-height: 100%;
}

.link-text {
    color: white
}

.link-visited:visited {
    color: white
}

/* mouse over link */
.link-hover:hover {
    color: rgb(235, 233, 215);
  text-shadow: 3px 3px #000000;
}

.head-stripe {
    background-color: #1c2730;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    height: max-content;
}

.border-right {
    border-right: 6px solid black;
}
.border-left {
    border-left: 6px solid black;
}

.intro-text {
    text-shadow: 3px 3px #000000;
}

.table-size {
    max-width: 100vw;
}

.dropdown-menu {
    background-color: #242424ff;
    border: none;
}

.dropdown-item {
    color: white;
}

.searchBar {
    max-width: 300px;
}

.extensionCards {
    background-color: #01587a;
}

.btn-primary {
    background-color: #01587a !important;
}

.btn-block {
    width: 100%;
}

.noteBox {
    display: flex !important;
    flex-direction: column-reverse !important;
}

.password-mismatch {
    color: white;
    outline: auto;
    background-color: red; 
    max-width: fit-content;
    border: 2px solid black;
}

.noBottomBorder {
    border-bottom: none;
    border-bottom-color: transparent;
}

.noteModal {
    background-color: #01587a;
}

.blf {
    height: 25px;
}

#collapsible-navbar-nav {
    background-color: #242424ff;
}

.adLogo {
    max-height: 60px;
}

.bookSearch {
    margin-bottom: -10px;
    width: 400px;
}

.PbNavCol {
    margin-bottom: -12px;
    margin-right: -100px;
    width: 100%;
}

.tableHeader {
    top: 78px;
    position: sticky;
}

.tableScroll {
    max-height: clamp(5em,10vh,250px);
    overflow: auto;
}

.chatButton {
    width: 10vh;
    color: white;
    margin-left: 80%;
    border-top: solid white 1px;
    border-right: solid white 1px;
    border-left: solid white 1px;
}

.noteCardBody {
    height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    background-color: #01587a;
}

.noteCardBodyUser {
    height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    background-color: #01587a;
}

.noButton {
    background: none;
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: none;
}

.animate-spinner {
    font-size: 50px;
    animation: color-change 1.5s infinite;
    text-shadow: 3px 10px 10px rgb(119, 119, 119);
}

.animate-spinner-h {
    font-size: 150px;
    animation: color-change 1.5s infinite;
    text-shadow: 3px 10px 10px rgb(119, 119, 119);
}

.animate-spinner-hoip {
    font-size: 120px;
    animation: color-change 1.5s infinite;
    text-shadow: 3px 10px 10px rgb(119, 119, 119);
}
  
@keyframes color-change {
    0% { color: #01587a; }
    50% { color: rgb(0, 94, 255); }
    100% { color: #01587a; }
}

.small-alert {
    height: min-content;
    width: 100%;
    border-style: none;
    background-color: gray;
    color: white;
}